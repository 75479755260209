exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-hygine-standard-js": () => import("./../../../src/pages/hygine-standard.js" /* webpackChunkName: "component---src-pages-hygine-standard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

